import { Box, Button, Card, CardContent, Divider, Grid, IconButton, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Edit as EditIcon } from '@material-ui/icons';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@material-ui/lab';
import { format } from 'timeago.js';
import { DownloadIcon, EmptyContainer, FileIcon, UploadIcon } from '../../../components';
import {
  DocumentVersion,
  PublicationHistory as PublicationHistoryType,
  PublicationRevision,
  PublicationSupportingDocument,
} from '../../../typings';
import { client } from '../../../utils/client';
import { getDownloadDetails } from '../../../utils/format';
import { useStyles } from './styles';

interface PublicationHistoryProps {
  history: Array<PublicationHistoryType>;
}

/**
 * @description Renders a Publication's history as a timeline of document versions.
 * @param props the PublicationHistory props
 * @returns a PublicationHistory component
 */
const PublicationHistory = (props: PublicationHistoryProps) => {
  const { history } = props;

  const classes = useStyles();

  if (history.length === 0) {
    return <EmptyContainer icon={'empty'} message={'Nothing to show.'} />;
  }

  return (
    <Card>
      <CardContent classes={{ root: classes.cardContent }}>
        <Timeline align="left">
          {history.map(({ documentVersion, revisions }, key) => (
            <HistoryItem key={key} documentVersion={documentVersion} revisions={revisions} />
          ))}
        </Timeline>
      </CardContent>
    </Card>
  );
};

type HistoryItemProps = {
  documentVersion: DocumentVersion;
  revisions: PublicationRevision[];
};

/**
 * Returns an History Item grouping
 * @param props the HistoryItem props
 * @returns a HistoryItem component
 */
const HistoryItem = (props: HistoryItemProps) => {
  const { documentVersion, revisions } = props;

  const classes = useStyles();
  const downloadLabel = 'Download';

  const { hasDownloadUrl, handleDownload } = getDownloadDetails(documentVersion);

  return (
    <TimelineItem classes={{ missingOppositeContent: classes.timelineItemMissingOppositeContent }}>
      <TimelineSeparator>
        <TimelineDot classes={{ root: classes.timelineDot }} color="secondary" variant="outlined">
          <UploadIcon />
        </TimelineDot>
        <TimelineConnector className={classes.secondaryTail} />
      </TimelineSeparator>
      <TimelineContent>
        <Grid container classes={{ root: classes.timelineContentGrid }}>
          <Grid item xs={7} classes={{ root: classes.timelineContentGridItem }}>
            <Typography classes={{ root: classes.versionTitleText }}>
              {documentVersion.createdByName} uploaded Version {documentVersion.versionNumber}
            </Typography>
          </Grid>
          <Grid item xs={3} classes={{ root: classes.timelineContentGridItem }}>
            <Typography>
              {new Date(documentVersion.createdDate).toLocaleString('en-us', {
                dateStyle: 'medium',
                timeStyle: 'short',
              })}
            </Typography>
          </Grid>
          <Grid item xs={2} classes={{ root: classes.timelineContentGridItem }} container justifyContent="flex-end">
            <Button
              onClick={handleDownload}
              color="secondary"
              variant={'outlined'}
              size={'small'}
              disabled={!hasDownloadUrl}
            >
              {downloadLabel}
            </Button>
          </Grid>

          <Grid item sm={12} md={7}>
            <Box mt={1.75}>
              <Comment text={documentVersion.changeLog} />
            </Box>
          </Grid>
        </Grid>
        <Divider classes={{ root: classes.divider }} />

        {revisions.map((revision, key) => (
          <RevisionItem key={key} revision={revision} />
        ))}
      </TimelineContent>
    </TimelineItem>
  );
};

type RevisionItemProps = {
  revision: PublicationRevision;
};

/**
 * Returns a single Revision Item
 * @param props the RevisionItem props
 * @returns a RevisionItem component
 */
const RevisionItem = (props: RevisionItemProps) => {
  const { revision } = props;

  const classes = useStyles();

  return (
    <Box key={revision.id} display="flex" mb={2}>
      <span key={revision.id} className={classes.editIconWrapper}>
        <EditIcon color="secondary" fontSize="small" style={{ width: 14, height: 14 }} />
      </span>
      <Box flexGrow={1}>
        <Typography classes={{ root: classes.revisionTitleText }}>
          {revision.owner.name} - {revision.subject}
        </Typography>
        <Grid container>
          <Grid item sm={12} md={7}>
            <Box mb={1} mt={1.75}>
              <Comment text={revision.comments ?? ''}></Comment>
            </Box>
          </Grid>
        </Grid>
        <Typography variant="body2">{format(revision.completedDateTime)}</Typography>
        {revision.documents && (
          <Box flexGrow={1} marginTop={2}>
            {revision.documents.map((document, key) => (
              <UploadedFile key={key} file={document} />
            ))}
          </Box>
        )}
      </Box>
      <Box flexGrow={1}></Box>
    </Box>
  );
};

type UploadFileProps = {
  file: PublicationSupportingDocument;
};

/**
 * Returns a single Uploaded File
 * @param props the UploadedFile props
 * @returns a UploadedFile component
 */
const UploadedFile = (props: UploadFileProps) => {
  const { file } = props;

  const theme = useTheme();
  const classes = useStyles();

  const handleDownload = async () => {
    const url = client.getDocumentDownloadUrl(file.id);
    window.location.href = url;
  };

  return (
    <div key={file.title} className={classes.uploadedFileWrapper}>
      <div style={{ marginRight: theme.spacing(2) }}>
        <FileIcon size={24} fileExtension={file.fileExtension} />
      </div>
      <Typography style={{ flexGrow: 1 }} variant="body2">
        {file.title + '.' + file.fileExtension}
      </Typography>
      <IconButton onClick={handleDownload} color="primary" size={'small'}>
        <DownloadIcon size={22} />
      </IconButton>
    </div>
  );
};

type CommentProps = {
  text: string;
};

/**
 * Renders text with comment styling
 * @param props the Comment props
 * @returns a Comment component
 */
const Comment = (props: CommentProps) => {
  const { text } = props;
  const classes = useStyles();

  return (
    <Typography variant="body2" classes={{ root: classes.comment }}>
      {text}
    </Typography>
  );
};

export default PublicationHistory;
