import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import OrgsProvider from '../../hooks/useOrgs';
import { useStyles } from '../AppBar/styles';
import { ChangeOrgDialog } from './ChangeOrgDialog';

/**
 * Renders the Change Organization menu item
 * @returns the component
 */
export const ChangeOrg = () => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button className={classes.unselectedItem} style={{ fontWeight: 'bold' }} onClick={handleOpen}>
        Change Organization
      </Button>
      <OrgsProvider>
        <ChangeOrgDialog open={open} onClose={handleClose} />
      </OrgsProvider>
    </>
  );
};
