import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useOrgs } from '../../hooks/useOrgs';
import { labels } from '../../pages/Profile/ProfileForm';
import { CachedSalesforceOrg } from '../../typings';
import { isValidURL } from '../../utils/format';

type AddOrgDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

/**
 * Renders an Add Organization dialog
 * @param props the Add Organization dialog properties
 * @returns the component
 */
export const AddOrgDialog = (props: AddOrgDialogProps) => {
  const { open, setOpen } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit, reset, formState } = useForm<CachedSalesforceOrg>({ defaultValues: {} });
  const { errors } = formState;
  const [, addOrg] = useOrgs();

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const onSubmit = async (org: CachedSalesforceOrg) => {
    try {
      addOrg(org);
      enqueueSnackbar('Salesforce organization has been added', { variant: 'success' });
      setOpen(false);
    } catch (error) {
      enqueueSnackbar(`An error occured when adding the Salesforce organization: ${error}`, { variant: 'error' });
    }
  };

  const onError = (error: any) => {
    enqueueSnackbar(`An error occured when adding the Salesforce organization: ${error}`, { variant: 'error' });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit, onError)} data-testid={'addorg-form'}>
        <DialogTitle id="form-dialog-title">Add Salesforce Organization</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Organization Label *"
            fullWidth
            error={!!errors.label}
            helperText={errors.label?.message}
            data-testid={'label-input'}
            {...register('label', { required: { value: true, message: labels.fieldRequired } })}
          />
          <TextField
            margin="dense"
            label="Salesforce Login URL *"
            fullWidth
            error={!!errors.loginUrl}
            helperText={errors.loginUrl?.message}
            data-testid={'loginurl-input'}
            {...register('loginUrl', {
              required: { value: true, message: labels.fieldRequired },
              validate: { isUrl: isValidURL },
            })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button color="secondary" variant="contained" type="submit">
            Add Organization
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
