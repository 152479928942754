import {
  Avatar,
  Card,
  Dialog,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Typography,
} from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import React, { useState } from 'react';
import { useOrgs } from '../../hooks/useOrgs';
import { client } from '../../utils/client';
import { generateAvatarLetters } from '../../utils/format';
import { AddOrgDialog } from './AddOrgDialog';

type ChangeOrgDialogProps = {
  open: boolean;
  onClose: () => void;
};

/**
 * Renders a Change Organization dialog
 * @param props the Change Organization dialog properties
 * @returns the component
 */
export const ChangeOrgDialog = (props: ChangeOrgDialogProps) => {
  const [open, setOpen] = useState(false);

  const [orgs, , removeOrg] = useOrgs();

  const handleRedirectToLogin = (org: any) => {
    client.setToken({ loginUrl: org.loginUrl });
    window.location.href = client.getAuthorizationUrl();
  };

  const handleClose = () => {
    props.onClose();
  };

  const handleAdd = () => {
    setOpen(true);
  };

  return (
    <>
      <Dialog open={props.open} onClose={handleClose}>
        <Card variant="outlined" style={{ width: 400 }}>
          <List subheader={<ListSubheader>Previously used Salesforce organizations</ListSubheader>}>
            {orgs.length === 0 && (
              <>
                <Divider component="li" />
                <ListSubheader style={{ textAlign: 'center' }}>{`No organization found.`}</ListSubheader>
              </>
            )}
            {orgs.map((org, key) => (
              <div key={key}>
                <Divider variant="middle" component="li" />
                <ListItemLink dense={true} onClick={() => handleRedirectToLogin(org)}>
                  <ListItemAvatar>
                    <Avatar>{generateAvatarLetters(org.label)}</Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    primary={org.label}
                    secondary={
                      <React.Fragment>
                        <Typography noWrap={true}>{org.loginUrl}</Typography>
                      </React.Fragment>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => removeOrg(key)}>
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItemLink>
              </div>
            ))}
            <Divider />
            <ListItemLink dense={true} variant={'primary'} onClick={handleAdd}>
              <ListItemIcon>
                <Add />
              </ListItemIcon>
              <ListItemText primary={'Add Salesforce Organization'} />
            </ListItemLink>
          </List>
        </Card>
      </Dialog>
      <AddOrgDialog open={open} setOpen={setOpen} />
    </>
  );
};

const ListItemLink = (props: any) => {
  return <ListItem button component="a" {...props} />;
};
